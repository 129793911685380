<template>
  <div class="projects-import">
    <!-- <el-row :gutter="24"
            class="prav-import">
      <el-col :span="24">
        <div class="insert-h">
          <div class="attr"></div>
          <div class="val">
            <el-checkbox v-model="isFromMyProj"
                         label="从我的项目中导入"></el-checkbox>
          </div>
        </div>
      </el-col>
    </el-row> -->
    <project-empty ref="empty" :authorization="authorization" :spaceList="spaceList"
                   v-show="!isFromMyProj"></project-empty>
  </div>
</template>

<script>
import ProjectEmpty from './ProjectEmpty.vue'
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'

export default {
  name: "ProjectImport",
  components: {
    ProjectEmpty
  },
  props: {
    spaceList: {
      type: Array,
      default: []
    }
  },
  setup(props, {expose}) {
    const $route = useRoute();
    let currentTemplate = ref(""), authorization = ref(true), isFromMyProj = ref(false);
    const empty = ref(null);

    const getDataFn = () => {
      if($route.params) {
        empty.value.setImportCode({id: $route.query.verId, code: $route.query.code});
      }
    };
    expose({
      getDataFn
    })

    return {
      authorization,
      isFromMyProj,
      empty
    }
  },
}
</script>

<style lang="scss" scoped>
.projects-import {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prav-import {
  width: 100%;
  max-width: 720px;
}
</style>