<template>
  <div class="projects-mgt">
    <el-scrollbar>
      <div class="projects-list"
           v-show="templateShow">
        <type-filter :list="filterList"
                @filterData="filterData" />
        <div class="project-content"
             v-for="(n,i) in projectList"
             :key="i">
          <div class="project">
            <div class="project-img">
              <div class="project-img-frame">
                <img :src="n.picture"
                     alt="">
              </div>
            </div>
            <span>{{n.name}}</span>
            <div class="project-shadow">
              <el-row>
                <el-button size="small"
                           @click="previewTemplate(n)">预览</el-button>
                <el-button size="small"
                           type="primary"
                           @click="useTemplate(n)">使用</el-button>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div class="project-enter">
        <project-empty ref="empty"
                       v-show="!templateShow"
                       :template="currentTemplate"
                       :spaceList="spaceList"
                       @changeTemplate="changeTemplate"></project-empty>
      </div>
    </el-scrollbar>
    <ScreenPreview :prjId="screen.prjId"
                 :obj="screen"
                 :isShare="false"
                 v-if="previewTemp"/>
  </div>
</template>

<script>
import ProjectEmpty from './ProjectEmpty.vue'
import ScreenPreview from 'views/outerChain/screen/screenPreview';
import _ from 'underscore';
import TypeFilter from 'components/common/Filter.vue';
import { ref, provide, onMounted, getCurrentInstance } from 'vue';

export default {
  name: "ProjectTemplate",
  components: {
    ProjectEmpty,
    ScreenPreview,
    TypeFilter,
  },
  props: {
    spaceList: {
      type: Array,
      default: []
    }
  },
  setup(props, {}) {
    let chooseComp = ref([]), projectList = ref([]), templateShow = ref(true), currentTemplate = ref({}), previewTemp = ref(false), 
    screen = ref({screenWidth: 1920, screenHeight:1400, components: []}), filterList = ref([]), recordData = ref("");
    const empty = ref(null);
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const actionApi = () => {};

    const getTemplates = () => {
      previewTemp.value = false;
      filterList.value = [{
        id: 'all',
        name: '全部'
      }];
      $api.sp.getAllPublishProjects({ type: 3 }).then(data => {
        let uniq = [];
        projectList.value = data;
        projectList.value.forEach(v => {
          if (v.tags) {
            v.tags.forEach(t => {
              if (!_.contains(uniq, t.id)) {
                filterList.value.push({
                  id: t.id,
                  name: t.name
                })
                uniq.push(t.id);
              }
            })
          }

          v.picture = v.pic == '-1' ? "/img/project.88c4099e.jpg" : "/file/" + v.pic;
        })
        recordData.value = JSON.stringify(projectList.value);
      })
    };

    const useTemplate = (obj) => {
      templateShow.value = false;
      currentTemplate.value = obj;
      empty.value.setupProject(currentTemplate.value);
    };

    const previewTemplate = (obj) => {
      previewTemp.value = true;
      screen.value = obj; //传入模板组件
    };

    const changeTemplate = () => {
      templateShow.value = true;
    };

    const shortcutKey = () => {
      document.addEventListener('keyup', (event) => {
        //esc退出全屏预览
        if (event.which === 27) {
          previewTemp.value = false;
          event.preventDefault();
        }
      }, false);
    };

    const filterData = (id) => {
      projectList.value = JSON.parse(recordData.value);
      if (id != 'all') {
        projectList.value = _.filter(projectList.value, v => {
          let tag = _.find(v.tags, t => {
            return t.id == id;
          })
          return !!tag;
        })
      }
    };

    const previewHide = () => {
      previewTemp.value = false;
    };

    provide ('selectedComp', chooseComp.value);
    provide ('acApi', actionApi);

    onMounted(() => {
      getTemplates();
      shortcutKey();
    })
    return {
      empty,
      screen,
      templateShow,
      filterData,
      filterList,
      currentTemplate,
      previewTemp,
      projectList,

      previewTemplate,
      useTemplate,
      changeTemplate,
      previewHide,
    }
  },
}
</script>

<style lang="scss" scoped>
.projects-mgt {
  width: 100%;
  height: 100%;
}

.projects-list {
  width: 100%;
  padding: 16px 16px;
  float: left;

  .line {
    width: 100%;
    float: left;
  }
}

.project-content {
  width: 20%;
  float: left;
  padding: 16px;

  .project {
    width: 100%;
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    overflow: hidden;
    cursor: pointer;
    transition: all 0.1s linear;
    border-radius: 8px;
    position: relative;

    &:hover {
      border-color: rgba(30, 170, 255, 0.8);
      box-shadow: 0 0 20px rgba(30, 170, 255, 0.4);

      span {
        color: rgb(30, 170, 255);
      }

      .project-shadow {
        opacity: 1;
      }
    }

    .project-img {
      width: 100%;
      float: left;
      padding-bottom: calc(100% * 9 / 16);
      position: relative;

      .project-img-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    span {
      width: 100%;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 0 16px;
      transition: all 0.1s linear;
    }

    .project-shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.1s linear;
    }
  }
}

@media (max-width: 1800px) {
  .project-content {
    width: 25%;
  }
}

@media (max-width: 1400px) {
  .project-content {
    width: calc(100% / 3);
  }
}

@media (max-width: 960px) {
  .project-content {
    width: 50%;
  }
}

.project-enter {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
<style lang="scss">
.fullscreen-template {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 95535;

  .stage-content {
    height: 100% !important;
  }
}

.screen-preview {
  .stage-canvas {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 10000000 !important;
    zoom: 100% !important;
    user-select: none !important;

    &:before {
      content: "按ESC键退出预览";
      width: 320px;
      height: 60px;
      margin: 0 -160px;
      position: absolute;
      top: 20px;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000000;
      background: rgba(20, 30, 40, 0.9);
      border-radius: 12px;
      font-size: 32px;
      color: #fff;
      opacity: 1;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
      pointer-events: none;
      -webkit-animation: fadeAminate 0.2s 3s linear forwards;
      -moz-animation: fadeAminate 0.2s 3s linear forwards;
      animation: fadeAminate 0.2s 3s linear forwards;
    }
  }
}
</style>